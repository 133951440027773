<template>
  <div
    v-if="!isGhost"
    class="group inline-flex items-center justify-center gap-2 rounded-sm fill-current px-6 py-4 text-base font-normal leading-[1.375rem] tracking-normal shadow-lg transition duration-100 ease-linear focus:outline-none disabled:pointer-events-none disabled:opacity-20"
    :class="{
      'bg-primary text-white  hover:bg-neutral-90 ': isPrimary,
      'bg-white text-primary': isSecondary,
      ' bg-transparent text-primary': isPrimaryAlternative,
      'bg-[#f5f6f7]': isTertiary,
    }"
  >
    <slot />
  </div>
  <span v-else>
    <slot />
  </span>
</template>

<script setup lang="ts">
const props = defineProps({
  variant: {
    type: String as PropType<string>,
    default: 'primary',
    validator: (val: string) =>
      [
        'primary',
        'primary-alternative',
        'secondary',
        'tertiary',
        'ghost',
      ].includes(val),
  },
})

const isPrimary = computed(() => props.variant === 'primary')
const isPrimaryAlternative = computed(
  () => props.variant === 'primary-alternative',
)
const isSecondary = computed(() => props.variant === 'secondary')
const isTertiary = computed(() => props.variant === 'tertiary')
const isGhost = computed(() => props.variant === 'ghost')
</script>
